<template>
    <div>
        <breadcrumb
            name="Notifications"
            title="Add New Notification"
            subtitle="Add"
            @onTitleClicked="backToList"
        />
        <div class="body">
            <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
            <div>
                <div>
                    <vs-row>
                        <vs-col
                            vs-lg="6"
                            vs-sm="12"
                            style="padding: 0 19px 0 19px"
                        >
                            <label for="target">Target Type</label>
                            <select id="target" v-model="target_type">
                                <option value="0" disabled>
                                    Choose Notification Target
                                </option>
                                <option value="-1">General</option>
                                <option value="1">Specific Car Model</option>
                                <option value="2">Specific News</option>
                                <option value="3">Recall Checker</option>
                            </select>
                        </vs-col>

                        <vs-col
                            vs-lg="6"
                            vs-sm="12"
                            style="padding: 0 19px 0 19px"
                        >
                            <div v-if="target_type === '1'">
                                <vs-select
                                    id="models"
                                    placeholder="Models"
                                    multiple
                                    class="selectExample"
                                    label="Models (hold on CTRL to pick another one) *"
                                    v-model="modelsIds"
                                >
                                    <vs-select-item
                                        :key="index"
                                        :value="model.id"
                                        :text="model.name_en"
                                        v-for="(model, index) in models"
                                    />
                                </vs-select>
                            </div>
                            <div v-if="target_type === '2'">
                                <label for="news">News</label>
                                <select id="news" v-model="news_id">
                                    <option value="0">Choose News</option>
                                    <option
                                        :value="news_item.id"
                                        v-for="(news_item, index) in news"
                                        :key="index"
                                        v-html="
                                            news_item.title_en || news_item.id
                                        "
                                    ></option>
                                </select>
                            </div>
                            <div
                                style="display: flex; align-items: center"
                                v-if="target_type === '3'"
                            >
                                <div class="flex-1" v-if="!importVIN">
                                    <label for="vin_numbers">
                                        Vin Numbers
                                        <span class="vin_number_hint">
                                            (Press
                                            <pre class="enter">enter</pre>
                                            to add a new VIN number)
                                        </span>
                                        *
                                    </label>
                                    <vue-tags-input
                                        class="vs-inputx vs-input--input medium"
                                        style="padding: 0.25rem"
                                        placeholder="Add Vin Number"
                                        v-model="vinNumber"
                                        :tags="vinNumbers"
                                        @tags-changed="
                                            (newVins) => (vinNumbers = newVins)
                                        "
                                    />
                                </div>
                                <div
                                    class="flex-1"
                                    v-else
                                    style="margin-left: 20px"
                                >
                                    <label for="to_file"
                                        >Or Upload Sheet File</label
                                    >
                                    <input
                                        style="margin-top: 10px"
                                        @change="uploadFile"
                                        type="file"
                                        ref="to_file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </div>
                                <vs-button
                                    style="margin-top: 30px; margin-left: 10px"
                                    :icon="
                                        importVIN ? 'keyboard' : 'attach_file'
                                    "
                                    :title="
                                        importVIN
                                            ? 'Type VINs'
                                            : 'Upload VINs Sheet'
                                    "
                                    @click="importVIN = !importVIN"
                                >
                                </vs-button>
                            </div>
                        </vs-col>
                    </vs-row>
                </div>
                <div>
                    <vs-row v-if="showSendTo">
                        <vs-col
                            vs-lg="6"
                            vs-sm="12"
                            style="padding: 0 19px 0 19px"
                        >
                            <label for="to">Send To</label>
                            <select id="to" v-model="to">
                                <option value="0" disabled>
                                    Send Notification To ...
                                </option>
                                <option value="all">All Users</option>
                                <option value="brand">
                                    Users of Specific Brand
                                </option>
                            </select>
                        </vs-col>

                        <vs-col
                            vs-lg="6"
                            vs-sm="12"
                            style="padding: 0 19px 0 19px"
                            v-if="to == 'brand'"
                        >
                            <vs-select
                                id="brands"
                                placeholder="Brands"
                                style="width: 100%; margin-top: 15px"
                                multiple
                                class="selectExample"
                                label="Brands (hold on CTRL to pick another one) *"
                                v-model="brandsIds"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="brand.id"
                                    :text="brand.name_en"
                                    v-for="(brand, index) in brands"
                                />
                            </vs-select>
                        </vs-col>
                    </vs-row>
                </div>

                <div class="english">
                    <vs-input
                        label="English Title*"
                        v-model="name_en"
                        size="medium"
                        class="input"
                        :danger="flags.name_en"
                        danger-text="This field is mandatory"
                    />
                    <vs-textarea
                        v-model="description_en"
                        label="English Details*"
                        height="175px"
                    />
                </div>
                <div class="arabic">
                    <vs-input
                        label="Arabic Title*"
                        v-model="name_ar"
                        size="medium"
                        class="input"
                        :danger="flags.name_ar"
                        danger-text="This field is mandatory"
                    />
                    <vs-textarea
                        v-model="description_ar"
                        label="Arabic Details*"
                        height="175px"
                    />
                </div>
            </div>
            <div class="optionBtns">
                <vs-button
                    :disabled="invalidForm"
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="confirmSaveData"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="$emit('cancel')"
                    >Cancel
                </vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
    name: "add-aplus",
    components: { Breadcrumb, HomeIcon, ChevronsRightIcon, VueTagsInput },
    props: {},
    data() {
        return {
            to: "0",
            to_file: null,
            target_type: "0",
            brandsIds: [],
            modelsIds: [],
            news_id: "0",
            name_ar: "",
            vinNumbers: [],
            vinNumber: "",
            description_ar: "",
            name_en: "",
            description_en: "",
            brands: [],
            models: [],
            news: [],
            editor: ClassicEditor,
            editorConfig: {
                placeholder: "Notification Details",
            },
            importVIN: false,
            flags: {
                name_ar: false,
                name_en: false,
                description_ar: false,
                description_en: false,
                lengthExceeded_ar: false,
                lengthExceeded_en: false,
            },
        };
    },
    methods: {
        backToList() {
            this.$emit("cancel");
        },
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to send this notification!",
                color: "primary",
                accept: () => this.saveData(),
                type: "confirm",
            });
        },
        resetData() {
            this.backToList();
        },
        uploadFile() {
            this.to_file = this.$refs.to_file.files[0];
        },
        saveData() {
            this.$vs.loading();

            let formData = new FormData();

            formData.append("title_en", this.name_en);
            formData.append("title_ar", this.name_ar);
            formData.append("description_ar", this.description_ar);
            formData.append("description_en", this.description_en);
            if (this.to_file) {
                formData.append("to_file", this.to_file);
            }

            if (this.target_type === "3") {
                formData.append(
                    "to",
                    this.vinNumbers.map((vin) => vin.text).toString()
                );
            } else {
                if (this.to === "all") {
                    formData.append("to", this.to);
                } else {
                    formData.append("to", this.brandsIds);
                    formData.append("target_id", this.brandsIds);
                }
            }

            formData.append("target_type", this.target_type);

            if (this.target_type === "1") {
                formData.append("target_id", this.modelsIds);
            } else if (this.target_type === "2") {
                formData.append("target_id", this.news_id);
            }

            const headers = { "Content-Type": "multipart/form-data" };

            this.$httpAdmin
                .post("notification/push", formData, { headers })
                .then((r) => {
                    console.log(r);
                    this.$vs.notify({
                        text: "Notification is sent successfully",
                        title: "Success",
                        color: "success",
                    });
                    this.$emit("saved");
                })
                .catch(() => {
                    //console.log(e);
                    this.$vs.notify({
                        text: "Error has occured!",
                        title: "Error",
                        color: "danger",
                    });
                });
        },
        getModels() {
            this.$vs.loading();
            this.$httpCars
                .get(`/models?per_page=100000000`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.models = res.data.data.models;
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getNews() {
            this.$vs.loading();
            this.$httpNews
                .get(`/news?per_page=100000000`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.news = res.data.data.data;
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getBrands() {
            this.$vs.loading();
            this.$httpCars
                .get(`/brands?per_page=100000000`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.brands = res.data.data.brands;
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    computed: {
        invalidForm() {
            if (
                this.flags.name_ar ||
                this.flags.name_en ||
                this.flags.description_ar ||
                this.flags.description_en ||
                this.flags.lengthExceeded_ar ||
                this.flags.lengthExceeded_en
            ) {
                return true;
            } else {
                return false;
            }
        },
        showSendTo() {
            return this.target_type == -1 || this.target_type == 2;
        },
    },
    watch: {
        target_type(value) {
            this.to = "0";
            this.brandsIds = [];
            this.modelsIds = [];
            this.news_id = "0";
            if (value === "1") {
                if (this.models.length === 0) {
                    this.getModels();
                }
            } else if (value === "2") {
                if (this.news.length === 0) {
                    this.getNews();
                }
            }
        },
        to(value) {
            if (value === "brand") {
                if (this.brands.length === 0) {
                    this.getBrands();
                }
            }
        },
        name_ar(v) {
            v === ""
                ? (this.flags.name_ar = true)
                : (this.flags.name_ar = false);
        },
        name_en(v) {
            v === ""
                ? (this.flags.name_en = true)
                : (this.flags.name_en = false);
        },
        description_ar(v) {
            v.replace(/(<([^>]+)>)/gi, "") === ""
                ? (this.flags.description_ar = true)
                : (this.flags.description_ar = false);
            v.replace(/(<([^>]+)>)/gi, "").length > 3000
                ? (this.flags.lengthExceeded_ar = true)
                : (this.flags.lenghtExceeded_ar = false);
        },
        description_en(v) {
            v.replace(/(<([^>]+)>)/gi, "") === ""
                ? (this.flags.description_en = true)
                : (this.flags.description_en = false);
            v.replace(/(<([^>]+)>)/gi, "").length > 3000
                ? (this.flags.lengthExceeded_en = true)
                : (this.flags.lengthExceeded_en = false);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../basic-styles/mixins";

%flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
}

.images {
    @include margin-y(30px);
    text-align: center;
}

.optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
        position: relative;
        left: 25px;
    }
}

.input {
    width: 100%;
    @include margin-y(30px);
}

.arabic,
.english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 0 20px;
}

h1 {
    margin-bottom: 25px;
}

.inputs {
    @extend %flex;
}

ul {
    li {
        display: inline;
    }
}

.clr {
    clear: both;
}

$primaryColor: #054f7c;

.breadcrumb {
    margin: 20px;
}

.back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
}

.main-component {
    color: $primaryColor;
}

.required {
    color: red;
    font-size: 1.5rem;
}

.mandatory {
    color: red;
}

.arabic,
.english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px;
}

.custom-class {
    color: $primaryColor;
}

.title {
    font-size: 2rem;
    font-weight: 400;
}

.separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
}

.label {
    color: #00000099;
    padding-left: 5px;
}

.settings {
    margin: 30px 0 30px 30px;

    .header {
        margin-bottom: 10px;
    }
}

label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
}

select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.vin_number_hint {
    // font-size: 11px;
    // font-weight: bold;
    // color: #000000;
    .enter {
        // padding: 2px;
        // background-color: #0000001a;
        // border-radius: 8px;
        display: inline-block;
    }
}

#models {
    margin-top: 15px;
    width: 100%;
    .con-select .vs-select--input {
        padding: 9px;
    }
}
</style>
